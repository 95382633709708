import Underline from "@tiptap/extension-underline";

const CustomUnderline = Underline.extend({
    priority: 99,
    addAttributes: () => {
        return {
            double: {
                default: false,
                renderHTML: ({ double }) => {
                    return {
                        "data-double": double,
                    };
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-double");
                    let nodeAttribute = htmlAttribute === "true" ? true : false;
                    return nodeAttribute;
                },
            },
            class: {
                renderHTML: (attributes) => {
                    if (attributes.double) {
                        return { class: "double-underline" };
                    } else {
                        return { class: "underline" };
                    }
                },
            },
        };
    },
    renderHTML: ({ HTMLAttributes }) => {
        return ["span", HTMLAttributes, 0];
    },
    parseHTML: () => {
        return [
            {
                tag: "span",
                getAttrs: (el) => {
                    if (el.hasAttribute("data-double")){
                        return {};
                    }else{
                        return false;
                    }
                },
            },
        ];
    },
});

export default CustomUnderline;
