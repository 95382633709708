import fontSelectItems from "../../views/Reports/fontSelectItems";
import colorSwatches from "../../views/Reports/colorSwatches";

export default {
    data() {
        return {
            mainMenu: [
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .undo()
                            .run(),
                    title: "Undo",
                    active: () => false,
                    icon: "mdi-undo-variant",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .redo()
                            .run(),
                    title: "Redo",
                    active: () => false,
                    icon: "mdi-redo-variant",
                    divider: () => true,
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run(),
                    active: () => this.editor.isActive("bold"),
                    title: "Bold",
                    icon: "mdi-format-bold",
                    color: "black",
                    height: "32",
                    width: "32",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run(),
                    active: () => this.editor.isActive("italic"),
                    title: "Italic",
                    icon: "mdi-format-italic",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleStrike()
                            .run(),
                    active: () => this.editor.isActive("strike"),
                    title: "Strike",
                    icon: "mdi-format-strikethrough-variant",
                    color: "black",
                    height: "32",
                    width: "32",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .run(),
                    active: () => this.editor.isActive("underline"),
                    title: "Underline",
                    icon: "mdi-format-underline",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .updateAttributes("underline", {
                                double: true,
                            })
                            .run(),
                    active: () =>
                        this.editor.isActive("underline", {
                            double: true,
                        }),
                    title: "Double underline",
                    icon: "mdi-drag-horizontal-variant",
                    divider: () => true,
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setTextAlign("left")
                            .run(),
                    active: () => this.editor.isActive({ textAlign: "left" }),
                    title: "Align left",
                    icon: "mdi-format-align-left",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setTextAlign("center")
                            .run(),
                    active: () => this.editor.isActive({ textAlign: "center" }),
                    title: "Align center",
                    icon: "mdi-format-align-center",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setTextAlign("right")
                            .run(),
                    active: () => this.editor.isActive({ textAlign: "right" }),
                    title: "Align right",
                    icon: "mdi-format-align-right",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setTextAlign("justify")
                            .run(),
                    active: () =>
                        this.editor.isActive({ textAlign: "justify" }),
                    title: "Justify",
                    icon: "mdi-format-align-justify",
                    divider: () => true,
                },
                {
                    command: () => {
                        if (this.editor.isActive("paragraph"))
                            this.editor
                                .chain()
                                .focus()
                                .decreaseIndent("paragraph")
                                .run();
                        if (this.editor.isActive("heading"))
                            this.editor
                                .chain()
                                .focus()
                                .decreaseIndent("heading")
                                .run();
                    },
                    title: "Indent decrease",
                    icon: "mdi-format-indent-decrease",
                },
                {
                    command: () => {
                        if (this.editor.isActive("paragraph"))
                            this.editor
                                .chain()
                                .focus()
                                .increaseIndent("paragraph")
                                .run();
                        if (this.editor.isActive("heading"))
                            this.editor
                                .chain()
                                .focus()
                                .increaseIndent("heading")
                                .run();
                    },
                    title: "Indent increase",
                    icon: "mdi-format-indent-increase",
                    divider: () => true,
                },
                {
                    command: () => {
                        this.editor
                            .chain()
                            .focus()
                            .setHorizontalRule()
                            .run();
                    },
                    title: "Horizontal rule",
                    icon: "mdi-minus",
                    divider: () => true,
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .setParagraph()
                            .run(),
                    active: () => this.editor.isActive("paragraph"),
                    title: "Paragraph",
                    icon: "mdi-format-pilcrow",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 1 })
                            .run(),
                    active: () => this.editor.isActive("heading", { level: 1 }),
                    title: "Heading 1",
                    icon: "mdi-format-header-1",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 2 })
                            .run(),
                    active: () => this.editor.isActive("heading", { level: 2 }),
                    title: "Heading 2",
                    icon: "mdi-format-header-2",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleHeading({ level: 3 })
                            .run(),
                    active: () => this.editor.isActive("heading", { level: 3 }),
                    title: "Heading 3",
                    icon: "mdi-format-header-3",
                    divider: () => true,
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleBulletList()
                            .run(),
                    active: () => this.editor.isActive("bulletList"),
                    title: "Bullet list",
                    icon: "mdi-format-list-bulleted",
                },
                {
                    command: () =>
                        this.editor
                            .chain()
                            .focus()
                            .toggleOrderedList()
                            .run(),
                    active: () => this.editor.isActive("orderedList"),
                    title: "Ordered list",
                    icon: "mdi-format-list-numbered",
                    divider: () => true,
                },
                {
                    command: () => (this.landscape = !this.landscape),
                    active: () => this.landscape,
                    title: this.landscape ? "Protrait" : "Landscape",
                    icon: "mdi-phone-rotate-landscape",
                },
            ],

            fontSizeSelectItems: [
                {
                    text: "8",
                    value: "8px",
                },
                {
                    text: "9",
                    value: "9px",
                },
                {
                    text: "10",
                    value: "10px",
                },
                {
                    text: "11",
                    value: "11px",
                },
                {
                    text: "12",
                    value: "12px",
                },
                {
                    text: "14",
                    value: "14px",
                },
                {
                    text: "16",
                    value: "16px",
                },
                {
                    text: "18",
                    value: "18px",
                },
                {
                    text: "20",
                    value: "20px",
                },
                {
                    text: "22",
                    value: "22px",
                },
                {
                    text: "24",
                    value: "24px",
                },
                {
                    text: "26",
                    value: "26px",
                },
                {
                    text: "28",
                    value: "28px",
                },
                {
                    text: "36",
                    value: "36px",
                },
                {
                    text: "48",
                    value: "48px",
                },
                {
                    text: "72",
                    value: "72px",
                },
            ],
            fontSelectItems: fontSelectItems,
            textColor: "#000000",
            colorSwatches: colorSwatches,
            displayColorPickerMenu: false,
        };
    },
    computed: {
        fontSelectedItem: {
            get() {
                if (
                    this.editor.isActive("textStyle", {
                        fontFamily: "Arial, Helvetica, sans-serif",
                    })
                ) {
                    return "Arial, Helvetica, sans-serif";
                }
                if (
                    this.editor.isActive("textStyle", {
                        fontFamily: '"Times New Roman", Times, serif',
                    })
                ) {
                    return '"Times New Roman", Times, serif';
                }
                if (
                    this.editor.isActive("textStyle", {
                        fontFamily: '"Georgia", Georgia, serif',
                    })
                ) {
                    return '"Georgia", Georgia, serif';
                }
                if (
                    this.editor.isActive("textStyle", {
                        fontFamily: "'Roboto', sans-serif",
                    })
                ) {
                    return "'Roboto', sans-serif";
                }
                if (
                    this.editor.isActive("textStyle", {
                        fontFamily: "'Lobster', cursive",
                    })
                ) {
                    return "'Lobster', cursive";
                }
                return null;
            },
            set() {},
        },
        fontSize: {
            get() {
                for (let size of this.fontSizeSelectItems) {
                    if (
                        this.editor.isActive("textStyle", {
                            fontSize: size.value,
                        })
                    ) {
                        return size.value;
                    }
                }
                return null;
            },
            set() {},
        },
    },
    methods: {
        onImageLoad(file) {
            if (file) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    this.editor
                        .chain()
                        .focus()
                        .setImage({ src: reader.result })
                        .run();
                };
            }
        },
        onFontChange(value) {
            this.editor
                .chain()
                .focus()
                .setFontFamily(value)
                .run();
        },
        onFontSizeChange(value) {
            this.editor
                .chain()
                .focus()
                .setMark("textStyle", { fontSize: value })
                .run();
        },
    },
};
