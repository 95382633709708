import Paragraph from "@tiptap/extension-paragraph";

const CustomParagraph = Paragraph.extend({
    addAttributes: () => {
        return {
            indent: {
                renderHTML: (attributes) => {
                    return attributes.indent
                        ? {
                              style: `margin-left: ${attributes.indent * 14}px`,
                              "data-indent": attributes.indent,
                          }
                        : null;
                },
                parseHTML: (el) => el.getAttribute("data-indent"),
            },
        };
    }
});

export default CustomParagraph;
