import TextStyle from "@tiptap/extension-text-style";

const TextStyleWithSize = TextStyle.extend({
    addAttributes: () => {
        return {
            fontSize: {
                default: null,
                renderHTML: (attributes) => {
                    if (attributes.fontSize) {
                        return {
                            style: `font-size: ${
                                attributes.fontSize
                            }; line-height: ${parseInt(attributes.fontSize) *
                                1.3}px`,
                            "data-fontsize": attributes.fontSize,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute('data-fontsize')
            },
        };
    },
});

export default TextStyleWithSize;