import Heading from "@tiptap/extension-heading";

const CustomHeading = Heading.extend({
    addAttributes: () => {
        return {
            ...Heading.config.addAttributes(),
            indent: {
                renderHTML: (attributes) => {
                    return attributes.indent
                        ? {
                              style: `margin-left: ${attributes.indent *
                                  14}px; line-height: 1.3em`,
                              "data-indent": attributes.indent,
                          }
                        : {
                              style: "line-height: 1.3em",
                          };
                },
                parseHTML: (el) => el.getAttribute("data-indent"),
            },
        };
    },
});

export default CustomHeading;
