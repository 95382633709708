import { Extension } from '@tiptap/core'

const Indentation = Extension.create({
    addCommands() {
        return {
            increaseIndent: (nodeType) => ({ editor, chain}) => {
                let { indent } = editor.getAttributes(nodeType);
                if (indent) { 
                    if (indent < 15){
                        ++indent;
                    }
                }else{
                    indent = 1;
                }
                return chain().updateAttributes(nodeType, { indent});
            },
            decreaseIndent: (nodeType) => ({ editor, chain}) => {
                let { indent } = editor.getAttributes(nodeType);
                if (indent && indent > 0) { 
                    --indent;
                    return chain().updateAttributes(nodeType, { indent});
                }else{
                    return false;
                }
            }
        }
    }
});

export default Indentation;