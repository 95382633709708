import Image from "@tiptap/extension-image";
import { VueNodeViewRenderer } from "@tiptap/vue-2";
import ImageView from "../nodeViews/ImageView";

const CustomImage = Image.extend({
    addAttributes() {
        return {
            ...Image.config.addAttributes(),
            float: {
                default: null,
                renderHTML: (attrs) => {
                    if (attrs.float) {
                        return {
                            style: `float: ${attrs.float}`,
                            "data-float": attrs.float,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute("data-float") ,
            },
            width: {
                renderHTML: (attrs) => {
                    if (attrs.width) {
                        return {
                            width: attrs.width,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute("width"),
            },
            height: {
                renderHTML: (attrs) => {
                    if (attrs.height) {
                        return {
                            "height": attrs.height,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute("height") ,
            },
            lockAspectRatio: {
                default: true,
                renderHTML: (attrs) => {
                    if (attrs.lockAspectRatio) {
                        return {
                            "data-lock-aspect-ratio": attrs.lockAspectRatio,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute("data-lock-aspect-ratio"),
            },
            aspectRatio: {
                default: "1",
                renderHTML: (attrs) => {
                    if (attrs.aspectRatio) {
                        return {
                            "data-aspect-ratio": attrs.aspectRatio,
                        };
                    }
                },
                parseHTML: (el) => el.getAttribute("data-aspect-ratio"),
            },
            alt:{
                default: "-",
                renderHTML: (attrs) => {
                    return {
                        alt: attrs.alt ? attrs.alt : attrs.title,
                    };
                },
                parseHTML: (el) => el.getAttribute("alt")
            }
        };
    },
    parseHTML: () => {
        return [
            {
                tag: "img",
            },
        ];
    },
    addNodeView() {
        return VueNodeViewRenderer(ImageView);
    },
});

export default CustomImage;
