<template>
    <node-view-wrapper
        class="node-view-wrapper"
        :style="{ float: displayMode }"
    >
        <div class="image-wrapper">
            <div class="image-with-menu" :class="{ selected }" data-drag-handle>
                <v-responsive
                    v-if="this.node.attrs.lockAspectRatio"
                    :aspect-ratio="this.node.attrs.aspectRatio"
                >
                    <v-lazy :options="{ threshold: .1 }">
                        <img :src="node.attrs.src" />
                    </v-lazy>
                </v-responsive>
                <img v-else :src="node.attrs.src" />
            </div>
            <div
                class="image-menu d-flex flex-column"
                :class="{ visible: selected }"
            >
                <v-btn-toggle v-model="selectedButton" dense>
                    <v-btn title="No wrap">
                        <v-icon>mdi-format-wrap-top-bottom</v-icon>
                    </v-btn>

                    <v-btn title="Wrap right">
                        <v-icon style="transform: rotateY(180deg);"
                            >mdi-format-wrap-inline</v-icon
                        >
                    </v-btn>
                    <v-btn title="Wrap left">
                        <v-icon>mdi-format-wrap-inline</v-icon>
                    </v-btn>
                </v-btn-toggle>
                <v-btn
                    :title="
                        this.node.attrs.lockAspectRatio
                            ? 'Unlock ratio'
                            : 'Lock ratio'
                    "
                    @click="
                        updateAttributes({
                            lockAspectRatio: !node.attrs.lockAspectRatio,
                        })
                    "
                >
                    <v-icon>{{
                        this.node.attrs.lockAspectRatio
                            ? "mdi-lock"
                            : "mdi-lock-open"
                    }}</v-icon>
                </v-btn>
            </div>
        </div>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper } from "@tiptap/vue-2";

export default {
    name: "ImageView",
    components: {
        NodeViewWrapper,
    },
    props: {
        node: {
            type: Object,
            required: true,
        },
        deleteNode: {
            type: Function,
        },
        selected: {
            type: Boolean,
        },
        updateAttributes: {
            type: Function,
        },
    },
    data() {
        return {
            selectedButton: 0,
            displayMode: null,
            observer: null,
        };
    },
    created() {
        switch (this.node.attrs.float) {
            case "right":
                this.selectedButton = 1;
                break;
            case "left":
                this.selectedButton = 2;
                break;
            default:
                this.selectedButton = 0;
        }
        this.observer = new ResizeObserver((entries) => {
            let contentRect = entries[0].contentRect;
            if (contentRect.width !== 0 && contentRect.height !== 0)
                this.updateAttributes({
                    width: contentRect.width,
                    height: contentRect.height,
                });
        });
        let image = new Image();
        image.onload = function() {
            this.updateAttributes({
                aspectRatio: (image.width / image.height).toString(),
            });
        }.bind(this);

        image.src = this.node.attrs.src;
    },
    mounted() {
        this.observer.observe(this.$el);
        let imageWithMenu = this.$el.querySelector(".image-with-menu");
        imageWithMenu.style.height = this.node.attrs.height + "px";
        imageWithMenu.style.width = this.node.attrs.width + "px";
    },
    watch: {
        selectedButton: function(oldVal, newVal) {
            if (oldVal !== newVal) {
                switch (this.selectedButton) {
                    case 1:
                        this.updateAttributes({ float: "right" });
                        this.displayMode = "right";
                        break;
                    case 2:
                        this.updateAttributes({ float: "left" });
                        this.displayMode = "left";
                        break;
                    default:
                        this.updateAttributes({ float: null });
                        this.displayMode = null;
                }
            }
        },
    },
    // beforeDestroy() {
    //     this.deleteNode();
    // },
};
</script>

<style lang="scss" scoped>
.node-view-wrapper {
    display: inline-block;
    .image-wrapper {
        position: relative;

        .image-with-menu {
            overflow: hidden;
            resize: both;
            img {
                width: 100%;
                height: 100%;
            }
            &.selected {
                outline: 2px solid blue;
            }
        }
        .image-menu {
            position: absolute;
            top: 0;
            right: -70px;
            visibility: hidden;
            &.visible {
                z-index: 1;
                visibility: visible;
            }
        }
    }

    .v-btn-toggle {
        flex-direction: column;
    }
}
</style>
